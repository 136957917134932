import { HttpsCallableResult, httpsCallable } from "firebase/functions";
import backend from "../../config/DatabaseConfig";

/**
 * @remarks - This is for testing purposes only so only email and name are required
 * @param {object} payload - The payload to be sent to the callable function
 */
export const refer = async (payload: {
    customer_id: string;
    customer_name: string;
    info: {
        first_name: string;
        last_name: string;
        dob?: string;
        email?: string;
    };
}): Promise<HttpsCallableResult> => {
    return httpsCallable<any, any>(
        backend.FUNCTIONS,
        "referrals-refer"
    )(payload);
};
