import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Group, Stack, Title, Text, Button } from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import ReferralsTable from "./ReferralsTable";
import { useBoundStore } from "../../store";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import ReferralForm, { FormValues } from "./ReferralForm";
import { refer } from "../../services/callables/referralCallable";

interface StateProps {
    tableLoading: boolean;
}

const ReferralsContainer = () => {
    const [{ tableLoading }, setState] = useSetState<StateProps>({
        tableLoading: false,
    });

    const params = useParams();
    const { id } = params;

    const {
        referrals,
        getReferralsById,
        deleteReferral,
        currentCustomer,
        getCustomerById,
    } = useBoundStore();

    const getReferrals = async () => {
        try {
            setState({ tableLoading: true });

            if (!id) return;

            await getReferralsById(id);
        } catch (e) {
            console.log(e);
        } finally {
            setState({ tableLoading: false });
        }
    };

    const removeReferral = async (recordId: string): Promise<void> => {
        try {
            setState({ tableLoading: true });

            await deleteReferral(recordId);

            modals.close("deleteReferral");

            notifications.show({
                title: "Success!",
                message: "Referral successfully deleted.",
                icon: <IconCheck />,
            });

            getReferrals();
        } catch (e) {
            console.log(e);
            const error = e as Error;
            notifications.show({
                title: "Error",
                message: error.message,
                color: "red",
                icon: <IconX />,
            });
        } finally {
            setState({ tableLoading: false });
        }
    };

    const handleRemoveRecord = (recordId: string) => {
        modals.openConfirmModal({
            id: "deleteReferral",
            title: (
                <Text fz="xl" fw={500}>
                    Delete Referral
                </Text>
            ),
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete this referral?
                </Text>
            ),
            onCancel: () => modals.close("deleteReferral"),
            onConfirm: () => removeReferral(recordId),
        });
    };

    const handleAddReferral = async ({
        firstName,
        lastName,
        email,
        phone,
        country,
    }: FormValues) => {
        if (!id || !currentCustomer) return;
        try {
            await refer({
                customer_id: currentCustomer?.id,
                customer_name: currentCustomer?.name,
                info: {
                    first_name: firstName,
                    last_name: lastName,
                    email,
                },
            });
            notifications.show({
                title: "Success!",
                message: "Referral successfully created.",
                icon: <IconCheck />,
            });
            await getReferrals();
        } catch (e) {
            console.log(e);
            const error = e as Error;
            notifications.show({
                title: "Error",
                message: error.message,
                color: "red",
                icon: <IconX />,
            });
        }
    };

    const handleOpenReferralFormModal = () => {
        modals.open({
            title: (
                <Text fz="xl" fw={500}>
                    Create referral
                </Text>
            ),
            centered: true,
            children: (
                <ReferralForm
                    onSubmit={async (values) => {
                        await handleAddReferral(values);
                        modals.closeAll();
                    }}
                />
            ),
        });
    };

    useEffect(() => {
        (async () => {
            await getReferrals();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get current customer
    useEffect(() => {
        (async () => {
            if (!id) return;
            await getCustomerById(id);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container size="xl" p="sm">
            <Stack>
                <Group position="apart">
                    <Title order={1} size="h2">
                        Referrals List
                    </Title>
                    <Button
                        onClick={handleOpenReferralFormModal}
                        disabled={!currentCustomer?.active}
                    >
                        Add Referral
                    </Button>
                </Group>
                <ReferralsTable
                    records={referrals}
                    removeRecord={handleRemoveRecord}
                    dataLoading={tableLoading}
                />
            </Stack>
        </Container>
    );
};

export default ReferralsContainer;
