import { ReactNode } from "react";
import {
    Title,
    Divider,
    Button,
    Paper,
    Space,
    Box,
    Select,
} from "@mantine/core";
import { IconDeviceFloppy } from "@tabler/icons-react";
import CustomerDetailsForm from "./CustomerDetailsForm";
import { Customer, WebUiConfig } from "../../store/AppSlice";
import { isNotEmpty, useForm } from "@mantine/form";
import {
    validateEmail,
    validatePhone,
    validateLogo,
} from "../../validation/validation";
import SidebarForm from "./SidebarForm";
import LogoSelectForm from "./LogoSelectForm";

export type FormValues = {
    email: string;
    name: string;
    phone: string;
    country: string;
    active: boolean;
    logo?: File;
    guidanceTitle?: string;
    guidanceContent: string;
    faqTitle: string;
    faqContent: string;
    chatFlow: string;
};

interface SectionProps {
    title: string;
    children: ReactNode;
}

interface SettingsFormProps {
    customer: Customer | undefined;
    submitting: boolean;
    onSubmit: (values: FormValues) => void;
    webUiConfig: WebUiConfig | undefined;
    chatFlowsData: any[];
}

const Section = (props: SectionProps) => {
    const { title, children } = props;

    return (
        <>
            <Title order={2} size="h2" mb="sm">
                {title}
            </Title>
            <Paper radius="md" p="lg" withBorder pos="relative" shadow="md">
                {children}
            </Paper>
            <Space h="lg" />
        </>
    );
};

const SettingsForm = (props: SettingsFormProps) => {
    const { customer, submitting, onSubmit, webUiConfig, chatFlowsData } =
        props;

    const { email, name, phone, country, active, chat_flow } = customer || {};
    const {
        guidance_title,
        guidance_content,
        faq_title,
        faq_content,
        logo_url,
    } = webUiConfig || {};

    const requiredError = "This field is required";

    const form = useForm<FormValues>({
        initialValues: {
            email: email || "",
            name: name || "",
            phone: phone || "",
            country: country || "",
            active: active || false,
            guidanceTitle: guidance_title || "",
            guidanceContent: guidance_content || "",
            faqTitle: faq_title || "Need help?",
            faqContent:
                faq_content ||
                "Select the FAQ button below to see Frequently Asked Questions.",
            chatFlow: chat_flow || "",
        },
        validateInputOnChange: true,
        validateInputOnBlur: true,
        validate: {
            email: (value) => validateEmail(value),
            name: isNotEmpty(requiredError),
            phone: (value) => validatePhone(value),
            logo: (value) => validateLogo(value, logo_url),
            guidanceContent: isNotEmpty(requiredError),
            faqTitle: isNotEmpty(requiredError),
            faqContent: isNotEmpty(requiredError),
            chatFlow: isNotEmpty(requiredError),
        },
    });

    const formOnSubmit = form.onSubmit((values) => onSubmit(values));

    const chatFlowOptions = chatFlowsData.map((flow) => {
        return { value: flow.id, label: flow.name };
    });

    return (
        <Box>
            <form noValidate onSubmit={formOnSubmit}>
                <Section title="Customer Details">
                    <CustomerDetailsForm form={form} />
                </Section>
                <Section title="Chat Flow">
                    <Select
                        label="Chat Flow"
                        placeholder="Select chat flow"
                        data={chatFlowOptions || []}
                        radius="md"
                        {...form.getInputProps("chatFlow")}
                        searchable
                        nothingFound="No options"
                        allowDeselect
                        withAsterisk
                        data-qa="customerSettings_chatFlowField"
                    />
                </Section>
                <Section title="Branding">
                    <LogoSelectForm form={form} customerLogoUrl={logo_url} />
                </Section>
                <Section title="Sidebar">
                    <SidebarForm form={form} />
                </Section>

                <Divider my="md" />

                <Button
                    type="submit"
                    leftIcon={<IconDeviceFloppy />}
                    size="md"
                    disabled={!form.isValid()}
                    loading={submitting}
                >
                    Save Changes
                </Button>
            </form>
        </Box>
    );
};

export default SettingsForm;
