import { Stack, Switch, TextInput, Text } from "@mantine/core";
import { validatePhone } from "../../validation/validation";
import PhoneField from "../../components/PhoneField";
import { UseFormReturnType } from "@mantine/form";

import type { FormValues } from "./SettingsForm";

interface CustomerDetailsFormProps {
    form: UseFormReturnType<FormValues>;
}

const CustomerDetailsForm = (props: CustomerDetailsFormProps) => {
    const { form } = props;

    return (
        <Stack>
            <TextInput
                label="Name"
                placeholder="Customer name"
                required
                radius="md"
                data-qa="customerSettings_nameField"
                {...form.getInputProps("name")}
            />
            <TextInput
                required
                label="Email"
                placeholder="hello@eql.ai"
                {...form.getInputProps("email")}
                data-qa="customerSettings_emailField"
                radius="md"
            />
            <PhoneField
                required
                label="Phone"
                {...form.getInputProps("phone")}
                onChange={(phone: string, country: string) => {
                    form.setFieldError("phone", null);
                    form.setValues({ phone, country });
                    form.isTouched("phone") &&
                        form.setFieldError("phone", validatePhone(phone));
                }}
                radius="md"
            />

            <Text fz="sm" fw={500} mb="-10px">
                Customer Status
            </Text>
            <Switch
                onLabel={<Text fz="sm">Active</Text>}
                offLabel={<Text fz="sm">Inactive</Text>}
                size="lg"
                {...form.getInputProps("active", { type: "checkbox" })}
                data-qa="customerSettings_statusField"
            />
        </Stack>
    );
};

export default CustomerDetailsForm;
